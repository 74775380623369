<script>
import Layout from '@layouts/main';
import ContentLoading from '@components/content-loading';
import FleetHeader from '@components/fleet/header';
import FleetPositionMap from '@components/fleet-position-map';
import CAR_TRACKER from '@graphql/car/queries/tracker.gql';
import CAR_TRACKING from '@graphql/car/queries/tracking.gql';
import CAR_PROFILE from '@graphql/car/queries/profile.gql';
import { makeQuery } from '@graphql/middleware';
import TrackerInfo from "@views/fleet/common/tracker-info.vue";
import BlocksInfo from "@views/fleet/common/blocks-info.vue";
import VisitReason from '@components/fleet/visit-reason'

export default {
  name: 'FleetTracker',
  page: {
    title: 'Informações do rastreador',
  },
  components: {
    BlocksInfo,
    TrackerInfo,
    Layout,
    ContentLoading,
    FleetHeader,
    FleetPositionMap,
    VisitReason
  },
  apollo: {
    tracking: {
      query: CAR_TRACKER,
      variables() {
        return { id: this.$route.params.id };
      },
      update: data => data.carTracking,
    },
    car: {
      query: CAR_PROFILE,
      variables() {
        return { id: this.$route.params.id };
      },
      update: data => data.car,
    },
  },
  mounted (){
    this.getCarTracking();
  },
  data() {
    return {
      tracking: null,
      trackingApi: null,
      mapLoad: false,
      visitReason: false,
    };
  },
  computed: {
    trackerData() {
      return { id: this.$route.params.id, ...this.tracking, ...this.car }
    },
    trackerDataApi() {
      return { id: this.$route.params.id, ...this.trackingApi, receivedAt: this.tracking?.received_at }
    },
    isRental() {
      return this.car.product === 'RENTAL';
    },
    battery() {
      return this.trackingApi?.battery || {};
    },
    trackingVin() {
      return this.tracking?.vin;
    }
  },
  methods: {
    handleVisitReason(reason) {
      this.visitReason = reason;
    },
    googleStaticMap(position) {
      const heading = position.heading;
      const apiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
      const url = `https://maps.googleapis.com/maps/api/streetview?size=400x300&fov=120&pitch=0
                &location=${position.location[0]},${position.location[1]}
                &heading=${heading}
                &key=${apiKey}`;
      return url.replace(/[\r\n ]+/g, '');
    },
    getCarTracking() {
      makeQuery(
        CAR_TRACKING,
        {
          input: {
            id: this.$route.params.id
          }
        },
      )
      .then((res) => {
        this.trackingApi = res.data.getTracking;
        this.mapLoad = true;
      })
      .catch((err) => {
        console.error(err);
        this.mapLoad = false;
      })
    },
    getLastEvent(eventName) {
      switch (eventName){
        case 'VEHICLE_BATTERY_DISCONNECTED':
          return this.$i18n.t('fleet.events.vehicleBatteryDisconnected');
        case 'VEHICLE_BATTERY_CONNECTED':
          return this.$i18n.t('fleet.events.vehicleBatteryConnected');
        case 'DEVICE_OTA_UPDATED':
          return this.$i18n.t('fleet.events.deviceOtaUpdated');
        case 'VEHICLE_OFF_MOVING':
          return this.$i18n.t('fleet.events.vehicleOffMoving');
        case 'DEVICE_POWER_ON':
          return this.$i18n.t('fleet.events.devicePowerOn');
        case 'DEVICE_POWER_OFF':
          return this.$i18n.t('fleet.events.devicePowerOff');
        case 'TOKEN_REGISTERED':
          return this.$i18n.t('fleet.events.tokenRegisteredEvent');
        case 'TOKEN_MATCH':
          return this.$i18n.t('fleet.events.tokenMatch');
        case 'DEVICE_SLEEP_REPORT':
          return this.$i18n.t('fleet.events.deviceSleepReport');
        case 'JAMMER_DETECTED':
          return this.$i18n.t('fleet.events.jammerDetectedEvent');
        case 'JAMMER_ACTIVATED':
          return this.$i18n.t('fleet.events.jammerActivated');
        case 'JAMMER_DEACTIVATED':
          return this.$i18n.t('fleet.events.jammerDeactivated');
        case 'VEHICLE_ENGINE_ON':
          return this.$i18n.t('fleet.events.vehicleEngineOn');
        case 'VEHICLE_IGNITION_ON':
          return this.$i18n.t('fleet.events.vehicleIgnitionOn');
        case 'VEHICLE_ENGINE_OFF':
          return this.$i18n.t('fleet.events.vehicleEngineOff');
        case 'VEHICLE_IGNITION_OFF':
          return this.$i18n.t('fleet.events.vehicleIgnitionOff');
        case 'DEVICE_ACCELEROMETER_ALIGNED':
          return this.$i18n.t('fleet.events.deviceAccelerometerAligned');
        case 'VEHICLE_SPEED_LIMIT_ABOVE':
          return this.$i18n.t('fleet.events.vehicleSpeedLimitAbove');
        case 'VEHICLE_SPEED_LIMIT_BELOW':
          return this.$i18n.t('fleet.events.vehicleSpeedLimitBelow');
        case 'VEHICLE_HARD_ACCELERATION':
          return this.$i18n.t('fleet.events.vehicleHardAcceleration');
        case 'VEHICLE_HARD_BREAK':
          return this.$i18n.t('fleet.events.vehicleHardBreak');
        case 'VEHICLE_HARD_TURN_LEFT':
          return this.$i18n.t('fleet.events.vehicleHardTurnLeft');
        case 'VEHICLE_HARD_TURN_RIGHT':
          return this.$i18n.t('fleet.events.vehicleHardTurnRight');
        case 'VEHICLE_SOFT_ACCELERATION':
          return this.$i18n.t('fleet.events.vehicleSoftAcceleration');
        case 'VEHICLE_SOFT_BREAK':
          return this.$i18n.t('fleet.events.vehicleSoftBreak');
        case 'VEHICLE_SOFT_TURN_LEFT':
          return this.$i18n.t('fleet.events.vehicleSoftTurnLeft');
        case 'VEHICLE_SOFT_TURN_RIGHT':
          return this.$i18n.t('fleet.events.vehicleSoftTurnRight');
        case 'VEHICLE_SOFT_CAR_CRASH':
          return this.$i18n.t('fleet.events.vehicleSoftCarCrash');
        case 'VEHICLE_HARD_CAR_CRASH':
          return this.$i18n.t('fleet.events.vehicleHardCarCrash');
        case 'TOKEN_EXPIRED':
          return this.$i18n.t('fleet.events.tokenExpired');
        case 'VEHICLE_HIGH_SPEED':
          return this.$i18n.t('fleet.events.vehicleHighSpeed');
        default:
          return this.$i18n.t('fleet.events.unkown');
      }
    }
  }
}
</script>
<template>
  <Layout>
    <fleet-header :id="$route.params.id" />
    <div v-if="!visitReason">
      <visit-reason @ok="handleVisitReason" />
    </div>
    <div v-else>
      <div v-if="!$apollo.loading">
        <div v-if="trackingVin">
          <b-card-group class="driver-data mb-4">
            <b-card body-class="p-0" style="min-height: 300px" v-if="mapLoad">
              <fleet-position-map name="car-location" :tracking="[trackerData]" :fleet = "true" />
            </b-card>
            <b-card v-if="mapLoad" body-class="p-0">
              <b-link
                title="Abrir em nova janela"
                :href="'https://www.google.com/maps/search/?api=1&query=' + trackerData.position.location[0] + ',' + trackerData.position.location[1]"
                target="_blank"
                :style="{backgroundSize: 'cover', display: 'block', width: '100%', height: '100%',  backgroundImage: `url(${googleStaticMap(trackerData.position)})` }"
                class="street-view-image"
              />
            </b-card>
          </b-card-group>
          <b-card-group>
            <b-card>
              <h3> {{ $i18n.t('fleet.titles.carInfo') }} </h3>
              <p>
                <i class="fa fa-road" aria-hidden="true" />
                {{ $i18n.t('fleet.texts.speed') }}: <strong>{{ trackerDataApi.speed || 0 }}Km/h</strong>
              </p>
              <p>
                <i class="fa fa-power-off" aria-hidden="true" />
                {{ $i18n.t('fleet.texts.ignition') }}: <strong>{{ trackerDataApi.engine ? $i18n.t('fleet.texts.on') : $i18n.t('fleet.texts.off') }}</strong>
              </p>
              <p>
              <i class="fa fa-refresh" aria-hidden="true" />
                {{ $i18n.t('fleet.texts.lastEvent') }}: <strong>{{ getLastEvent(trackerDataApi.behavior) || $i18n.t('fleet.texts.noInfoFound') }}</strong>
              </p>
              <p>
                <i class="fa fa-battery-three-quarters" />
                {{ $i18n.t('fleet.texts.batteryVoltage') }}: <strong>{{ battery.vehicleBattery || $i18n.t('fleet.texts.noInfoFound') }} V</strong>
              </p>
              <p>
                <i class="fa fa-barcode" aria-hidden="true" />
                {{ $i18n.t('fleet.texts.vin') }}:
                <strong>
                  <span class="texts font-weight-bold" id="clip-vin">
                    {{ trackingVin || $i18n.t('fleet.texts.noInfoFound') }}
                    <b-button variant="link" size="sm" @click="$copyText('clip-vin', trackingVin)">
                      <i class="fa fa-clone" aria-hidden="true" />
                    </b-button>
                  </span>
                </strong>
              </p>
            </b-card>
            <b-card>
              <h3>{{ $i18n.t('fleet.titles.trackerInfo') }}</h3>
              <tracker-info :tracker-data="trackerDataApi" mode="tracker" />
              <p>
                <i class="fa fa-barcode" aria-hidden="true" />
                {{ $i18n.t('fleet.texts.trackerSerialNumber') }}:
                <strong>
                  <span class="texts font-weight-bold" id="clip-device-id">
                    {{ trackerDataApi.deviceId || $i18n.t('fleet.texts.noInfoFound') }}
                    <b-button variant="link" size="sm" @click="$copyText('clip-device-id', trackerDataApi.deviceId)">
                      <i class="fa fa-clone" aria-hidden="true" />
                    </b-button>
                  </span>
                </strong>
              </p>
            </b-card>
          </b-card-group>
          <b-card-group>
            <b-card>
              <h3>{{ $i18n.t('fleet.titles.tracking') }}</h3>
              <p>
                <i class="fa fa-tachometer" aria-hidden="true" />
                {{ $i18n.t('fleet.texts.vehicleMoving') }}: <strong>{{ !trackerDataApi.flags.vehicleMoving ? $i18n.t('fleet.texts.no') : $i18n.t('fleet.texts.yes') }}</strong>
              </p>
              <p>
                <i class="fa fa-map-marker" aria-hidden="true" />
                {{ $i18n.t('fleet.texts.vehicleAtKovi') }}: <strong>{{ !trackerDataApi.flags.vehicleAtKovi ? $i18n.t('fleet.texts.no') : $i18n.t('fleet.texts.yes') }}</strong>
              </p>
              <p>
                <i class="fa fa-microchip" aria-hidden="true" />
                {{ $i18n.t('fleet.texts.jammerDetected') }}: <strong>{{ !trackerDataApi.flags.jammerDetected ? $i18n.t('fleet.texts.no') : $i18n.t('fleet.texts.yes') }}</strong>
              </p>
              <p>
                <i class="fa fa-exclamation-triangle" aria-hidden="true" />
                {{ $i18n.t('fleet.texts.vehicleZombie') }}: <strong>{{ !trackerDataApi.flags.vehicleZombie ? $i18n.t('fleet.texts.no') : $i18n.t('fleet.texts.yes') }}</strong>
              </p>
              <p>
                <i class="fa fa-key" aria-hidden="true" />
                {{ $i18n.t('fleet.texts.tokenDisabled') }}: <strong>{{ !trackerDataApi.flags.tokenDisabled ? $i18n.t('fleet.texts.no') : $i18n.t('fleet.texts.yes') }}</strong>
              </p>
              <p>
                <i class="fa fa-bell-slash" aria-hidden="true" />
                {{ $i18n.t('fleet.texts.beepDisabled') }}: <strong>{{ !trackerDataApi.flags.beepDisabled ? $i18n.t('fleet.texts.no') : $i18n.t('fleet.texts.yes') }}</strong>
              </p>
            </b-card>
            <b-card>
              <h3>{{ $i18n.t('fleet.titles.blocks') }}</h3>

              <blocks-info :tracker-data="trackerDataApi" mode="tracker" />

              <div v-if="!isRental">
                <p v-if="trackerDataApi.flags.tokenActivated && !trackerDataApi.engine" class="text-success">
                  <i class="fa fa-check-circle" aria-hidden="true" />
                  <strong> {{ $i18n.t('fleet.texts.virtualTokenAvailable') }}</strong>
                </p>
                <p v-else class="text-danger">
                  <i class="fa fa-times-circle" aria-hidden="true" />
                  <strong> {{ $i18n.t('fleet.texts.virtualTokenUnvailable') }}</strong>
                </p>
              </div>
              <p><strong></strong></p>
            </b-card>
          </b-card-group>
        </div>
        <div v-else>
          <b-alert variant="danger" :show="true">
            {{ $i18n.t('fleet.texts.alertNoInfo') }}
          </b-alert>
        </div>
      </div>
      <div v-else class="animated fadeIn">
        <content-loading />
      </div>
    </div>
  </Layout>
</template>
